:root {
  --ReactInputVerificationCode-itemWidth: calc(100% / 8)!important;
  --ReactInputVerificationCode-itemHeight: 4rem !important;
  --ReactInputVerificationCode-itemSpacing: 1rem;
}
@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/HelveticaFont/Helvetica.ttf") format("truetype");
}
body {
  margin: 0;
  background-color: #edf2f6;
  font-family: "Roboto", sans-serif;
  white-space: pre-line;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 12em;
}
*::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: #969ba0a4;
  border-radius: 12em;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* @keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadein {
  animation: linear 100ms normal fadein;
} */



@-webkit-keyframes beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.beat {
  animation: beat linear 0.3s alternate infinite;
  -webkit-animation: beat linear 0.3s alternate infinite;
}

tspan {
  white-space: pre;
}
.s0 {
  fill: none;
  stroke: rgb(99, 115, 129);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5;
}

.s1 {
  fill: none;
  stroke: #ff5656;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5;
}
